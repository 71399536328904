module.exports = {
  en: {
    locale: "en",
    messages: {
      hello: "Hello! How are you?"
    }
  },

  bn: {
    locale: "bn",
    messages: {
      hello: "Hello! How are you?"
    }
  }
};
